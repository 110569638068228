const formatJobs = (data: any): any => {
  return data?.map((job: any) => {
    return {
      address: job?.vacancy_location?.vacancy_location,
      address_short_text: job?.vacancy_location?.vacancy_location_short,
      team_member: job?.title,
      slug: job?.slug?.current,
    };
  });
};

export default formatJobs;
