import React, { ReactNode } from 'react';
import { Container, HeaderText, PageSection, Image } from '@/components/_root';
import { OverlayContainer } from './styled';
import * as styles from './style.module.css';

interface Item {
  title: string;
  icon: string;
  description: ReactNode;
}

interface Props {
  title?: string;
  items: Item[];
}

const WhatsDifferentSection = ({ title = "What's different?", items }: Props): JSX.Element => {
  return (
    <OverlayContainer>
      <PageSection paddingAmount="small">
        <Container>
          <HeaderText size="h2" mode="dark" className="text-center">
            {title}
          </HeaderText>
          <WhatsDifferentSection.Items items={items} />
        </Container>
      </PageSection>
    </OverlayContainer>
  );
};

WhatsDifferentSection.Items = ({ items }: Props): JSX.Element => {
  return (
    <div className={`grid grid-cols-1 gap-11 mt-14 md:mt-32 ${styles.gridWrapper}`}>
      {items?.map((item, index) => (
        <WhatsDifferentSection.Item key={index} item={item} />
      ))}
    </div>
  );
};

WhatsDifferentSection.Item = ({
  item: { title, description, icon },
}: {
  item: Item;
}): JSX.Element => {
  const splitted = title.split(' ');
  const half = Math.ceil(title.split(' ').length / 2);
  return (
    <div className="text-white md:text-center ">
      <div className="flex md:flex-col items-center justify-between ">
        <Image src={icon} alt="" className="order-2 md:order-1 w-16 h-16" />
        <div className="text-white mt-6 mb-8 order-1">
          <h3>{splitted.slice(0, half).join(' ')}</h3>
          <h3>{splitted.slice(half, splitted.length).join(' ')}</h3>
        </div>
      </div>
      <div className="flex flex-col gap-6 md:w-10/12 md:mx-auto w-full lg:w-full">
        {description}
      </div>
    </div>
  );
};

export default WhatsDifferentSection;
