import HoverList from '@/components/hover-list';
import React, { ReactNode } from 'react';
import * as styles from '../style.module.css';

interface Props {
  children: ReactNode;
}

export const HeadingWrapper = ({ children }: Props): JSX.Element => (
  <div className="pb-8 md:pb-12">{children}</div>
);

export const Filter = ({ children }: Props): JSX.Element => {
  return <div className={`pb-10 md:pb-24 flex ${styles.filter}`}>{children}</div>;
};

export const BlogWrapper = ({ children }: Props): JSX.Element => (
  <HoverList className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 lg:gap-x-8 gap-y-12 transition duration-300 ease-in-out">
    {children}
  </HoverList>
);
