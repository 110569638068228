import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '@/components/_layout';
import Seo from '@/components/_layout/seo';
import ServiceBannerSection from '@/components/service-banner-section';
import ServiceAboutSection from '@/components/service-about-section';
import TechSeoSection from '@/components/tech-seo-section';
import ExpertiseSection from '@/components/expertise-section';
import ItWorkSection from '@/components/it-work-section';
import WhatsDifferentSection from '@/components/whats-different-section';

import { getTitle } from '@/utils/helper/getTitle';
import CaseStudiesSlider from '@/components/case-studies-slider';
import SEOExperts from '@/components/seo-experts-slider';
import Blog from '@/components/blog';
import OurOffices from '@/components/our-offices';
import ClientsFeedback from '@/components/clients-feedback';
import formatCaseStudies from '@/utils/helper/format-case-studies';
import formatServiceExperts from '@/utils/helper/format-service-experts';
import formatJobs from '@/utils/helper/format-jobs';
import { hasInvalidBlogData, setBlogFeaturedData } from '@/utils/helper/blog';
import BlockContent from '@/components/block-content';

const Service = ({ data, location, pageContext }: PageProps<any>): JSX.Element => {
  const {
    // @ts-ignore
    breadcrumb: { crumbs },
  } = pageContext;

  let blogList = data?.sanityAllServices?.service_opinions?.opinions;
  if (hasInvalidBlogData(blogList)) {
    blogList = setBlogFeaturedData(data?.allSanityBlog?.edges);
  }

  return (
    <Layout
      location={location}
      crumbLabel={data?.sanityAllServices?.service_banner?.title?.title_outlined}
      crumbs={crumbs}
    >
      <Seo
        title={
          data?.sanityAllServices?.metaInfo?.pageMetaName || data?.sanityBlog?.title || 'Services'
        }
        description={data?.sanityAllServices?.metaInfo?.pageMetaDescription}
        pageMetadata={data?.sanityAllServices?.metaInfo?.metadata}
        canonical={data?.sanityAllServices?.metaInfo?.canonical}
      />
      <ServiceBannerSection
        backgroundImageUrl={data?.sanityAllServices?.service_banner?.background_image}
        quoteText={data?.sanityAllServices?.service_banner?.sub_header}
        title={getTitle(data?.sanityAllServices?.service_banner)}
      />
      <ServiceAboutSection
        floating
        bannerPosition="right"
        sectionTitle={data?.sanityAllServices?.service_about?.title}
        body={data?.sanityAllServices?.service_about?._rawDescription}
        bannerImageUrl={data?.sanityAllServices?.service_about?.banner_image}
      />

      {data?.sanityAllServices?.case_studies.length ? (
        <CaseStudiesSlider
          isDark
          sectionTitle={{ solid: `Our Case Studies` }}
          caseStudies={formatCaseStudies(data?.sanityAllServices?.case_studies, true)}
          containerClasses={`pt-32 pb-32`}
        />
      ) : (
        <div />
      )}
      <TechSeoSection
        sectionTitle={data?.sanityAllServices?.service_tech_seo?.title}
        body={data?.sanityAllServices?.service_tech_seo?._rawDescription}
        bannerImageUrl={data?.sanityAllServices?.service_tech_seo?.banner_image}
        imageOrderFirst
      />
      <ExpertiseSection
        sectionTitle={data?.sanityAllServices?.service_expertise?.title}
        items={data?.sanityAllServices?.service_expertise?.expertises}
      />
      <ItWorkSection
        sectionTitle={data?.sanityAllServices?.service_it_work?.title}
        body={data?.sanityAllServices?.service_it_work?._rawDescription}
        bannerPosition="right"
        bannerImageUrl={data?.sanityAllServices?.service_it_work?.banner_image}
      />
      <SEOExperts
        title={getTitle(data?.sanityAllServices?.service_experts)}
        caseStudies={formatServiceExperts(data?.sanityAllServices?.service_experts?.experts)}
      />
      <WhatsDifferentSection
        title={data?.sanityAllServices?.service_difference?.title}
        items={data?.sanityAllServices?.service_difference?.differents}
      />
      <div className="relative bg-white">
        <Blog
          blogs={blogList}
          title={{ solid: data?.sanityAllServices?.service_opinions?.title }}
        />
      </div>
      {data?.sanityAllServices?.service_job?.vacancies?.length ? (
        <OurOffices
          title={{
            ...getTitle(data?.sanityAllServices?.service_job),
            inline: true,
          }}
          teams={formatJobs(data?.sanityAllServices?.service_job?.vacancies)}
          body={data?.sanityAllServices?.service_job?._rawDescription}
        />
      ) : (
        <div />
      )}
      <ClientsFeedback
        title={{ solid: data?.sanityAllServices?.service_clients_feedback?.title }}
        feedbacks={data?.sanityAllServices?.service_clients_feedback?.service_clients_feedback}
        isGradient
      />
    </Layout>
  );
};

export default Service;

export const query = graphql`
  query serviceQuery($id: String!) {
    allSanityBlog(limit: 3, sort: { fields: _createdAt, order: DESC }) {
      edges {
        node {
          id
          slug {
            current
          }
          title
          excerpt
          thumbnail {
            asset {
              gatsbyImageData(width: 350, placeholder: BLURRED)
            }
          }
        }
      }
    }

    sanityAllServices(id: { eq: $id }) {
      id
      title
      metaInfo {
        pageMetaDescription
        pageMetaName
        metadata {
          metaContent
          metaName
        }
        canonical
      }
      service_about {
        title
        _rawDescription
        banner_image {
          asset {
            gatsbyImageData(width: 550, placeholder: BLURRED)
          }
        }
      }
      service_tech_seo {
        title
        banner_image {
          asset {
            gatsbyImageData(width: 550, placeholder: BLURRED)
          }
        }
        _rawDescription
      }
      service_it_work {
        title
        _rawDescription
        banner_image {
          asset {
            gatsbyImageData(width: 550, placeholder: BLURRED)
          }
        }
      }
      service_expertise {
        title
        expertises {
          title
          description
        }
      }
      service_difference {
        title
        differents {
          description
          title
          icon {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      service_banner {
        background_image {
          asset {
            gatsbyImageData(width: 800, placeholder: BLURRED)
          }
        }
        title {
          title_solid
          title_outlined
          is_outline_first
        }
        sub_header
      }

      service_opinions {
        title
        opinions {
          love_count
          like_count
          clap_count
          blog {
            id
            slug {
              current
            }
            title
            excerpt
            thumbnail {
              asset {
                gatsbyImageData(width: 300, placeholder: BLURRED)
              }
            }
          }
        }
      }

      case_studies {
        id
        slug {
          current
        }
        title {
          title_solid
          title_outlined
          is_outline_first
        }
        banner_title {
          title_solid
          title_outlined
          is_outline_first
        }
        client
        live_date
        excerpt
        case_study_title
        category {
          title
        }
        _rawBody
        banner {
          asset {
            gatsbyImageData(width: 550, placeholder: BLURRED)
          }
        }
        thumbnail {
          asset {
            gatsbyImageData(width: 350, placeholder: BLURRED)
          }
        }
        work_project_result {
          title
          description
          button {
            url
            title
          }
        }
      }

      service_experts {
        title {
          title_outlined
          title_solid
          is_outline_first
        }
        experts {
          photo {
            asset {
              gatsbyImageData(width: 350, placeholder: BLURRED)
            }
          }
          position
          name
          is_current_member
          description
        }
      }
      service_job {
        title {
          title_solid
          title_outlined
          is_outline_first
        }
        _rawDescription
        vacancies {
          id
          title
          salary
          contract_type
          _rawBody
          slug {
            current
          }
          apply_button {
            url
            title
          }
          vacancy_teams {
            id
            team_name
          }
          vacancy_location {
            vacancy_location
            vacancy_location_short
          }
        }
      }

      service_clients_feedback {
        title
        service_clients_feedback {
          quote_secondary
          quote_primary
          client_name
        }
      }
    }
  }
`;
