import React from 'react';
import BannerDescription, { BannerDescriptionProps } from '@/components/banner-description';
import { PageSection } from '@/components/_root';
import BlockContent from '@/components/block-content';

const ItWorkSection = (props: BannerDescriptionProps): JSX.Element => {
  const description = <BlockContent blocks={props?.body} />;
  return (
    <PageSection>
      <BannerDescription {...props} body={description} />
    </PageSection>
  );
};

export default ItWorkSection;
