import React from 'react';
import BannerDescription, { BannerDescriptionProps } from '@/components/banner-description';
import { Button } from '@/components/_root';
import BlockContent from '../block-content';

const ServiceAboutSection = (props: BannerDescriptionProps): JSX.Element => {
  // const description = <div className="md:pb-11 lg:pb-0">{props.body}</div>;
  const description = <BlockContent blocks={props?.body} />;

  return (
    <BannerDescription
      {...props}
      body={description}
      footerActionButton={
        <Button
          shape="round"
          size="large"
          className="mt-8 px-8 block w-full lg:inline-block lg:w-auto"
          hoverVersion="bordered"
          href={'/contact'}
        >
          Get in touch
        </Button>
      }
    />
  );
};

export default ServiceAboutSection;
